/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteAplicaciones = /* GraphQL */ `
  mutation DeleteAplicaciones($id: Int!) {
    deleteAplicaciones(id: $id) {
      id
      nombreApp
      descripcion
      logo
      appUrl
      delet
    }
  }
`;
export const createAplicaciones = /* GraphQL */ `
  mutation CreateAplicaciones(
    $createAplicacionesInput: CreateAplicacionesInput!
  ) {
    createAplicaciones(createAplicacionesInput: $createAplicacionesInput) {
      id
      nombreApp
      descripcion
      logo
      appUrl
      delet
    }
  }
`;
export const updateAplicaciones = /* GraphQL */ `
  mutation UpdateAplicaciones(
    $updateAplicacionesInput: UpdateAplicacionesInput!
  ) {
    updateAplicaciones(updateAplicacionesInput: $updateAplicacionesInput) {
      id
      nombreApp
      descripcion
      logo
      appUrl
      delet
    }
  }
`;
export const deleteClientes = /* GraphQL */ `
  mutation DeleteClientes($id: Int!) {
    deleteClientes(id: $id) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      delet
    }
  }
`;
export const createClientes = /* GraphQL */ `
  mutation CreateClientes($createClientesInput: CreateClientesInput!) {
    createClientes(createClientesInput: $createClientesInput) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      delet
    }
  }
`;
export const updateClientes = /* GraphQL */ `
  mutation UpdateClientes($updateClientesInput: UpdateClientesInput!) {
    updateClientes(updateClientesInput: $updateClientesInput) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      delet
    }
  }
`;
export const deleteEmpresa = /* GraphQL */ `
  mutation DeleteEmpresa($id: Int!) {
    deleteEmpresa(id: $id) {
      id
      estatus
      rfc
      tipoPersona
      nombreComercial
      calle
      noExt
      noInt
      colonia
      localidad
      estado
      municipio
      pais
      codigoPostal
      telefono
      correoElectronico
      logo
      idCliente
      delet
    }
  }
`;
export const createEmpresa = /* GraphQL */ `
  mutation CreateEmpresa($createEmpresaInput: CreateEmpresaInput!) {
    createEmpresa(createEmpresaInput: $createEmpresaInput) {
      id
      estatus
      rfc
      tipoPersona
      nombreComercial
      calle
      noExt
      noInt
      colonia
      localidad
      estado
      municipio
      pais
      codigoPostal
      telefono
      correoElectronico
      logo
      idCliente
      delet
    }
  }
`;
export const updateEmpresa = /* GraphQL */ `
  mutation UpdateEmpresa($updateEmpresaInput: UpdateEmpresaInput!) {
    updateEmpresa(updateEmpresaInput: $updateEmpresaInput) {
      id
      estatus
      rfc
      tipoPersona
      nombreComercial
      calle
      noExt
      noInt
      colonia
      localidad
      estado
      municipio
      pais
      codigoPostal
      telefono
      correoElectronico
      logo
      idCliente
      delet
    }
  }
`;
export const deleteMetodoPago = /* GraphQL */ `
  mutation DeleteMetodoPago($id: Int!) {
    deleteMetodoPago(id: $id) {
      id
      nombreTarjeta
      customerIdConekta
      idEmpresa
      idCliente
      predeterminado
      delet
    }
  }
`;
export const createMetodoPago = /* GraphQL */ `
  mutation CreateMetodoPago($createMetodoPagoInput: CreateMetodoPagoInput!) {
    createMetodoPago(createMetodoPagoInput: $createMetodoPagoInput) {
      id
      nombreTarjeta
      customerIdConekta
      idEmpresa
      idCliente
      predeterminado
      delet
    }
  }
`;
export const updateMetodoPago = /* GraphQL */ `
  mutation UpdateMetodoPago($updateMetodoPagoInput: UpdateMetodoPagoInput!) {
    updateMetodoPago(updateMetodoPagoInput: $updateMetodoPagoInput) {
      id
      nombreTarjeta
      customerIdConekta
      idEmpresa
      idCliente
      predeterminado
      delet
    }
  }
`;
export const deletePaquete = /* GraphQL */ `
  mutation DeletePaquete($id: Int!) {
    deletePaquete(id: $id) {
      id
      idPaquete
      idAplicacion
      delet
    }
  }
`;
export const createPaquete = /* GraphQL */ `
  mutation CreatePaquete($createPaqueteInput: CreatePaqueteInput!) {
    createPaquete(createPaqueteInput: $createPaqueteInput) {
      id
      idPaquete
      idAplicacion
      delet
    }
  }
`;
export const updatePaquete = /* GraphQL */ `
  mutation UpdatePaquete($updatePaqueteInput: UpdatePaqueteInput!) {
    updatePaquete(updatePaqueteInput: $updatePaqueteInput) {
      id
      idPaquete
      idAplicacion
      delet
    }
  }
`;
export const deletePlanes = /* GraphQL */ `
  mutation DeletePlanes($id: Int!) {
    deletePlanes(id: $id) {
      id
      nombrePaquete
      descripcion
      costoMensual
      costoAnual
      fechaFin
      delet
    }
  }
`;
export const createPlanes = /* GraphQL */ `
  mutation CreatePlanes($createPlanesInput: CreatePlanesInput!) {
    createPlanes(createPlanesInput: $createPlanesInput) {
      id
      nombrePaquete
      descripcion
      costoMensual
      costoAnual
      fechaFin
      delet
    }
  }
`;
export const updatePlanes = /* GraphQL */ `
  mutation UpdatePlanes($updatePlanesInput: UpdatePlanesInput!) {
    updatePlanes(updatePlanesInput: $updatePlanesInput) {
      id
      nombrePaquete
      descripcion
      costoMensual
      costoAnual
      fechaFin
      delet
    }
  }
`;
export const deleteRenovaciones = /* GraphQL */ `
  mutation DeleteRenovaciones($id: Int!) {
    deleteRenovaciones(id: $id) {
      id
      idUsuario
      idCliente
      idEmpresa
      idApp
      idTransaccion
      fecha
      dias
      fechaRenovacion
      delet
    }
  }
`;
export const createRenovaciones = /* GraphQL */ `
  mutation CreateRenovaciones(
    $createRenovacionesInput: CreateRenovacionesInput!
  ) {
    createRenovaciones(createRenovacionesInput: $createRenovacionesInput) {
      id
      idUsuario
      idCliente
      idEmpresa
      idApp
      idTransaccion
      fecha
      dias
      fechaRenovacion
      delet
    }
  }
`;
export const updateRenovaciones = /* GraphQL */ `
  mutation UpdateRenovaciones(
    $updateRenovacionesInput: UpdateRenovacionesInput!
  ) {
    updateRenovaciones(updateRenovacionesInput: $updateRenovacionesInput) {
      id
      idUsuario
      idCliente
      idEmpresa
      idApp
      idTransaccion
      fecha
      dias
      fechaRenovacion
      delet
    }
  }
`;
export const deleteTransacciones = /* GraphQL */ `
  mutation DeleteTransacciones($id: Int!) {
    deleteTransacciones(id: $id) {
      id
      monto
      numUsuarios
      idPlan
      idProcesador
      fecha
      idEmpresa
      delet
    }
  }
`;
export const createTransacciones = /* GraphQL */ `
  mutation CreateTransacciones(
    $createTransaccionesInput: CreateTransaccionesInput!
  ) {
    createTransacciones(createTransaccionesInput: $createTransaccionesInput) {
      id
      monto
      numUsuarios
      idPlan
      idProcesador
      fecha
      idEmpresa
      delet
    }
  }
`;
export const updateTransacciones = /* GraphQL */ `
  mutation UpdateTransacciones(
    $updateTransaccionesInput: UpdateTransaccionesInput!
  ) {
    updateTransacciones(updateTransaccionesInput: $updateTransaccionesInput) {
      id
      monto
      numUsuarios
      idPlan
      idProcesador
      fecha
      idEmpresa
      delet
    }
  }
`;
export const deleteUsuarios = /* GraphQL */ `
  mutation DeleteUsuarios($id: Int!) {
    deleteUsuarios(id: $id) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      idCliente
      delet
    }
  }
`;
export const createUsuarios = /* GraphQL */ `
  mutation CreateUsuarios($createUsuariosInput: CreateUsuariosInput!) {
    createUsuarios(createUsuariosInput: $createUsuariosInput) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      idCliente
      delet
    }
  }
`;
export const updateUsuarios = /* GraphQL */ `
  mutation UpdateUsuarios($updateUsuariosInput: UpdateUsuariosInput!) {
    updateUsuarios(updateUsuariosInput: $updateUsuariosInput) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      idCliente
      delet
    }
  }
`;
export const deleteEmpresaAplicacionUsuario = /* GraphQL */ `
  mutation DeleteEmpresaAplicacionUsuario($id: Int!) {
    deleteEmpresaAplicacionUsuario(id: $id) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const createEmpresaAplicacionUsuario = /* GraphQL */ `
  mutation CreateEmpresaAplicacionUsuario(
    $createEmpresaAplicacionUsuarioInput: CreateEmpresaAplicacionUsuarioInput!
  ) {
    createEmpresaAplicacionUsuario(
      createEmpresaAplicacionUsuarioInput: $createEmpresaAplicacionUsuarioInput
    ) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const updateEmpresaAplicacionUsuario = /* GraphQL */ `
  mutation UpdateEmpresaAplicacionUsuario(
    $updateEmpresaAplicacionUsuarioInput: UpdateEmpresaAplicacionUsuarioInput!
  ) {
    updateEmpresaAplicacionUsuario(
      updateEmpresaAplicacionUsuarioInput: $updateEmpresaAplicacionUsuarioInput
    ) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const createDocumento = /* GraphQL */ `
  mutation CreateDocumento(
    $input: CreateDocumentoInput!
    $condition: ModelDocumentoConditionInput
  ) {
    createDocumento(input: $input, condition: $condition) {
      id
      file
      documentName
      name
      email
      day
      month
      year
      dateLimit
      message
      sign
      signers
      estatus
      hash
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumento = /* GraphQL */ `
  mutation UpdateDocumento(
    $input: UpdateDocumentoInput!
    $condition: ModelDocumentoConditionInput
  ) {
    updateDocumento(input: $input, condition: $condition) {
      id
      file
      documentName
      name
      email
      day
      month
      year
      dateLimit
      message
      sign
      signers
      estatus
      hash
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumento = /* GraphQL */ `
  mutation DeleteDocumento(
    $input: DeleteDocumentoInput!
    $condition: ModelDocumentoConditionInput
  ) {
    deleteDocumento(input: $input, condition: $condition) {
      id
      file
      documentName
      name
      email
      day
      month
      year
      dateLimit
      message
      sign
      signers
      estatus
      hash
      createdAt
      updatedAt
    }
  }
`;
