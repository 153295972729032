/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAplicaciones = /* GraphQL */ `
  query GetAplicaciones($id: Int!) {
    getAplicaciones(id: $id) {
      id
      nombreApp
      descripcion
      logo
      appUrl
      delet
    }
  }
`;
export const listAplicacioness = /* GraphQL */ `
  query ListAplicacioness {
    listAplicacioness {
      id
      nombreApp
      descripcion
      logo
      appUrl
      delet
    }
  }
`;
export const getClientes = /* GraphQL */ `
  query GetClientes($id: Int!) {
    getClientes(id: $id) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      delet
    }
  }
`;
export const getClientesByUID = /* GraphQL */ `
  query GetClientesByUID($uid: String!) {
    getClientesByUID(uid: $uid) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      delet
    }
  }
`;
export const listClientess = /* GraphQL */ `
  query ListClientess {
    listClientess {
      id
      uid
      nombre
      apellido
      correo
      telefono
      delet
    }
  }
`;
export const getEmpresa = /* GraphQL */ `
  query GetEmpresa($id: Int!) {
    getEmpresa(id: $id) {
      id
      estatus
      rfc
      tipoPersona
      nombreComercial
      calle
      noExt
      noInt
      colonia
      localidad
      estado
      municipio
      pais
      codigoPostal
      telefono
      correoElectronico
      logo
      idCliente
      delet
    }
  }
`;
export const getEmpresaByCID = /* GraphQL */ `
  query GetEmpresaByCID($idCliente: Int!) {
    getEmpresaByCID(idCliente: $idCliente) {
      id
      estatus
      rfc
      tipoPersona
      nombreComercial
      calle
      noExt
      noInt
      colonia
      localidad
      estado
      municipio
      pais
      codigoPostal
      telefono
      correoElectronico
      logo
      idCliente
      delet
    }
  }
`;
export const listEmpresas = /* GraphQL */ `
  query ListEmpresas {
    listEmpresas {
      id
      estatus
      rfc
      tipoPersona
      nombreComercial
      calle
      noExt
      noInt
      colonia
      localidad
      estado
      municipio
      pais
      codigoPostal
      telefono
      correoElectronico
      logo
      idCliente
      delet
    }
  }
`;
export const getMetodoPago = /* GraphQL */ `
  query GetMetodoPago($id: Int!) {
    getMetodoPago(id: $id) {
      id
      nombreTarjeta
      customerIdConekta
      idEmpresa
      idCliente
      predeterminado
      delet
    }
  }
`;
export const listMetodoPagos = /* GraphQL */ `
  query ListMetodoPagos {
    listMetodoPagos {
      id
      nombreTarjeta
      customerIdConekta
      idEmpresa
      idCliente
      predeterminado
      delet
    }
  }
`;
export const getPaquete = /* GraphQL */ `
  query GetPaquete($id: Int!) {
    getPaquete(id: $id) {
      id
      idPaquete
      idAplicacion
      delet
    }
  }
`;
export const listPaquetes = /* GraphQL */ `
  query ListPaquetes {
    listPaquetes {
      id
      idPaquete
      idAplicacion
      delet
    }
  }
`;
export const getPlanes = /* GraphQL */ `
  query GetPlanes($id: Int!) {
    getPlanes(id: $id) {
      id
      nombrePaquete
      descripcion
      costoMensual
      costoAnual
      fechaFin
      delet
    }
  }
`;
export const listPlaness = /* GraphQL */ `
  query ListPlaness {
    listPlaness {
      id
      nombrePaquete
      descripcion
      costoMensual
      costoAnual
      fechaFin
      delet
    }
  }
`;
export const getRenovaciones = /* GraphQL */ `
  query GetRenovaciones($id: Int!) {
    getRenovaciones(id: $id) {
      id
      idUsuario
      idCliente
      idEmpresa
      idApp
      idTransaccion
      fecha
      dias
      fechaRenovacion
      delet
    }
  }
`;
export const listRenovacioness = /* GraphQL */ `
  query ListRenovacioness {
    listRenovacioness {
      id
      idUsuario
      idCliente
      idEmpresa
      idApp
      idTransaccion
      fecha
      dias
      fechaRenovacion
      delet
    }
  }
`;
export const getTransacciones = /* GraphQL */ `
  query GetTransacciones($id: Int!) {
    getTransacciones(id: $id) {
      id
      monto
      numUsuarios
      idPlan
      idProcesador
      fecha
      idEmpresa
      delet
    }
  }
`;
export const listTransaccioness = /* GraphQL */ `
  query ListTransaccioness {
    listTransaccioness {
      id
      monto
      numUsuarios
      idPlan
      idProcesador
      fecha
      idEmpresa
      delet
    }
  }
`;
export const getUsuarios = /* GraphQL */ `
  query GetUsuarios($id: Int!) {
    getUsuarios(id: $id) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      idCliente
      delet
    }
  }
`;
export const getUsuariosByUID = /* GraphQL */ `
  query GetUsuariosByUID($uid: String!) {
    getUsuariosByUID(uid: $uid) {
      id
      uid
      nombre
      apellido
      correo
      telefono
      idCliente
      delet
    }
  }
`;
export const currentUser = /* GraphQL */ `
  query CurrentUser($uidUsuario: String!) {
    currentUser(uidUsuario: $uidUsuario) {
      apellido
      idEmpresa
      logo
      nombre
      nombreComercial
      uidUsuario
    }
  }
`;
export const listUsuarioss = /* GraphQL */ `
  query ListUsuarioss {
    listUsuarioss {
      id
      uid
      nombre
      apellido
      correo
      telefono
      idCliente
      delet
    }
  }
`;
export const getEmpresaAplicacionUsuario = /* GraphQL */ `
  query GetEmpresaAplicacionUsuario($uidUsuario: String!) {
    getEmpresaAplicacionUsuario(uidUsuario: $uidUsuario) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const listEmpresaAplicacionUsuarios = /* GraphQL */ `
  query ListEmpresaAplicacionUsuarios($uidUsuario: String!) {
    listEmpresaAplicacionUsuarios(uidUsuario: $uidUsuario) {
      activo
      appUrl
      dias
      fechaRenovacion
      idApp
      idEmpresa
      idMenu
      logo
      nombreApp
      nombreComercial
    }
  }
`;
export const getDocumento = /* GraphQL */ `
  query GetDocumento($id: ID!) {
    getDocumento(id: $id) {
      id
      file
      documentName
      name
      email
      day
      month
      year
      dateLimit
      message
      sign
      signers
      estatus
      hash
      createdAt
      updatedAt
    }
  }
`;
export const listDocumentos = /* GraphQL */ `
  query ListDocumentos(
    $filter: ModelDocumentoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        file
        documentName
        name
        email
        day
        month
        year
        dateLimit
        message
        sign
        signers
        estatus
        hash
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
